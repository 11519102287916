
//Dev BASE_URL
export const BASE_URL = 'https://backendsuria.suriaanddurweshtraders.com'

// export const BASE_URL = 'http://localhost:8081'




export const role = localStorage.getItem("role");
export const Name = localStorage.getItem("contactPerson");
export const branchId = localStorage.getItem("branchId");
export const branchName = localStorage.getItem("branchName");